import React from 'react'
import * as u from '@jsmanifest/utils'
import TextField, { TextFieldProps } from '@mui/material/TextField'

export interface InputProps {
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'email'
    | 'file'
    | 'hidden'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'slug'
    | 'search'
    | 'slug'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
}

function getValue(type: InputProps['type'], value: string) {
  switch (type) {
    case 'slug':
      return value.replace(/\s/, '-').toLowerCase()
    default:
      return value
  }
}

function getType(type: InputProps['type']) {
  switch (type) {
    case 'slug':
      return 'text'
    default:
      return type
  }
}

function Input({
  InputProps,
  onChange: onChangeProp,
  rows,
  maxRows,
  type = 'text',
  ...rest
}: InputProps & TextFieldProps) {
  const { current: isControlled } = React.useRef(u.isFnc(onChangeProp))
  const [value, setValue] = React.useState('')

  const _onChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      isControlled ? onChangeProp?.(evt) : setValue(evt.target.value)
    },
    [],
  )

  return (
    <TextField
      InputProps={{ ...InputProps, rows, maxRows }}
      value={getValue(type, value)}
      onChange={_onChange}
      type={getType(type)}
      {...rest}
    />
  )
}

export default Input
