import React from 'react'

export type RequestAction =
  | { type: 'pending'; pending: boolean }
  | { type: 'success'; success: boolean }
  | { type: 'error'; error: null | Error }
  | { type: 'timedOut'; timedOut: boolean }
  | { type: 'set-state'; state: Partial<RequestState> }

export interface RequestState {
  pending: boolean
  success: boolean
  error: null | Error
  timedOut: boolean
}

const initialState: RequestState = {
  pending: false,
  success: false,
  error: null,
  timedOut: false,
}

function reducer(
  state: RequestState = initialState,
  action: RequestAction,
): RequestState {
  switch (action.type) {
    case 'pending':
      return { ...state, pending: action.pending }
    case 'success':
      return { ...state, success: action.success }
    case 'error':
      return { ...state, error: action.error }
    case 'timedOut':
      return { ...state, timedOut: action.timedOut }
    case 'set-state':
      return { ...state, ...action.state }
    default:
      return state
  }
}

function useRequestState() {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const start = React.useCallback(() => {
    dispatch({
      type: 'set-state',
      state: { ...initialState, pending: true },
    })
  }, [])

  const setPending = React.useCallback(
    (pending) => dispatch({ type: 'pending', pending }),
    [],
  )
  const setSuccess = React.useCallback(
    (success) => dispatch({ type: 'success', success }),
    [],
  )
  const setError = React.useCallback(
    (err: Error | null) => dispatch({ type: 'error', error: err }),
    [],
  )
  const setTimedOut = React.useCallback(
    (timedOut) => dispatch({ type: 'timedOut', timedOut }),
    [],
  )

  return {
    ...state,
    start,
    setPending,
    setSuccess,
    setError,
    setTimedOut,
  }
}

export default useRequestState
