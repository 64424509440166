import axios from 'axios'
import qs from 'querystring'

export interface SubscribeToNewsletterOptions {
  email?: string
  tags?: {
    tagId: string
  }[]
}

const api = (function () {
  const pathToNetlifyFuncs =
    process.env.NODE_ENV === 'development'
      ? '127.0.0.1:3000/graphql'
      : '.netlify/functions'
  // const pathToNetlifyFuncs = '.netlify/functions'

  function getGetResponseUri(path: string) {
    return 'https://api.getresponse.com/v3' + path
  }

  return {
    getResponse: {
      async cancelSendingNewsletter() {},
      async createNewsletter() {
        const url = getGetResponseUri('/newsletters')
        const data = {}
        await axios.post(url, data)
      },
      async deleteNewsletter() {},
      async fetchNewslettersList() {},
      async fetchNewsletter() {},
      async fetchNewsletterActivities() {},
      async fetchNewsletterThumbnail() {},
      async fetchNewsletterStatistics() {},
      async fetchTotalNewsletterStatistics() {},
      async sendNewsletterDraft() {},
      async subscribeToNewsletter({
        email,
        tags,
      }: SubscribeToNewsletterOptions): Promise<any> {
        const url = `${pathToNetlifyFuncs}/subscribe`
        const pageUrl =
          typeof window !== 'undefined' ? window.location.href : ''
        const params = { email, pageUrl, tags }
        const response = await axios.post(url, params, { baseURL: '/' })
        if (typeof response.data.code === 'number') throw response.data
        return response.data
      },
      async subscribeToNewsletter_next(
        id: string,
        options: { email?: string; tagIds?: Array<{ tagId: string }> },
      ) {
        //
      },
    },

    async submitNetlifyForm(name: string, values: any) {
      const data = qs.stringify({ 'form-name': name, ...values })
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      return axios.post('/', data, { headers })
    },
    async submitContactForm(values: any) {
      const data = qs.stringify({ 'form-name': 'contact', ...values })
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      return axios.post('/', data, { headers })
    },
  }
})()

export default api
