import React from 'react'
import * as u from '@jsmanifest/utils'
import { LinkProps } from '@mui/material/Link'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'

export interface StartEndIconObject
  extends React.HTMLAttributes<HTMLSpanElement> {
  name?: string
}

export interface ButtonProps extends Omit<MuiButtonProps, 'startIcon'> {
  component?: React.ElementType<any>
  startIcon?: StartEndIconObject | React.ReactNode
  href?: string
  to?: string
  rel?: string
  target?: string
  muiLinkProps?: LinkProps
}

function Button({
  className,
  children,
  startIcon: startIconProp,
  endIcon: endIconProp,
  ...props
}: ButtonProps) {
  let startIcon

  if (startIconProp) {
    startIcon = renderIcon(startIconProp)
  }

  return (
    <MuiButton
      style={{
        display: !!startIcon || !!endIconProp ? 'flex' : 'inline-block',
      }}
      className={className}
      startIcon={startIcon}
      endIcon={endIconProp}
      color="inherit"
      {...props}
    >
      {children}
    </MuiButton>
  )
}

function renderIcon(icon: StartEndIconObject | any): React.ReactNode {
  let Icon

  if (u.isStr(icon)) {
    // Icon = getIcon(icon as IconName)
    // if (Icon) return <Icon />
  } else if (React.isValidElement(icon)) {
    return icon
  } else {
    // if (icon && isPlainObject(icon)) {
    //   Icon = getIcon(icon?.name)
    //   if (!Icon) {
    //     console.log(
    //       'No icon name matched in the icon object. Rendering icon disabled',
    //     )
    //     return null
    //   }
    //   return <Icon />
    // }
  }

  return null
}

export default Button
