import React from 'react'
import Loading from 'components/common/Loading'
import Input from 'components/common/Input'
import Button from 'components/common/Button'
import { toastError, toastSuccess } from 'utils'
import { DefaultMeta } from 'components/SEO'
import Page from 'components/Page'
import useRequestState from 'hooks/useRequestState'
import api from 'app/api'

export interface ContactFormValues {
  name: string
  email: string
  message: string
}

export interface ContactFormProps {
  onSubmit(e: React.SyntheticEvent): Promise<void>
  submitting?: boolean
}

function ContactForm({
  onSubmit,
  submitting,
}: React.PropsWithChildren<ContactFormProps>) {
  const [values, setValues] = React.useState<ContactFormValues>({
    name: '',
    email: '',
    message: '',
  })

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist()
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <form
      style={{ position: 'relative', width: '100%' }}
      method="post"
      name="contact"
      onSubmit={onSubmit}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <Loading isLoading={submitting}>Sending your message....</Loading>
      <input type="hidden" name="form-name" value="contact" />
      <Input
        name="name"
        label="Name"
        value={values.name}
        onChange={onChange}
        margin="dense"
        fullWidth
      />
      <Input
        name="email"
        label="Email"
        value={values.email}
        onChange={onChange}
        type="email"
        margin="dense"
        fullWidth
        required
      />
      <Input
        name="message"
        label="Message"
        value={values.message}
        onChange={onChange}
        rows={15}
        maxRows={30}
        margin="dense"
        multiline
        fullWidth
        required
      />
      <div style={{ margin: '12px 0' }}>
        <Button
          type="submit"
          disabled={submitting}
          variant="outlined"
          size="large"
        >
          Submit
        </Button>
      </div>
    </form>
  )
}

function ContactPage() {
  const { pending, start, setPending, setSuccess, setError } = useRequestState()

  async function onSubmit(e: React.SyntheticEvent) {
    try {
      e.preventDefault()
      start()

      const target: typeof e.target & {
        name?: { value: string }
        email?: { value: string }
        message?: { value: string }
      } = e.target

      const values = {
        name: target.name?.value,
        email: target.email?.value,
        message: target.message?.value,
      }

      await api.submitNetlifyForm('contact', values)
      setPending(false)
      setSuccess(true)
      toastSuccess('Thank you! You will be reached shortly.')
    } catch (error) {
      setPending(false)
      setError(error)
      toastError(
        error.message ||
          'An error occurred and we could not submit your contact message. ' +
            'Please try again later.',
      )
    }
  }

  return (
    <>
      <DefaultMeta />
      <Page
        title="Contact"
        subtitle="Leave a message and you will receive a response within 1-2 days"
        variant="post"
      >
        <ContactForm onSubmit={onSubmit} submitting={pending} />
      </Page>
    </>
  )
}

export default ContactPage
